import React from "react"
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';

const StyledSection = styled.a`
    display: block;
    scroll-behavior: smooth;
    cursor: pointer;
    width: auto;
    
    &.current {
        color: red;
    }
`;


const Section = ({ sectionId, children }) => {

    return (
        <StyledSection id={sectionId} onClick={() => scrollTo(sectionId)} children={children}>
           {children}
        </StyledSection>
    )
}

export default Section;