import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledTableOfContentsWrapper = styled.div`
    position: fixed;
        right: 0;
        top: 0;
        z-index: 3;
        transform: translate(100%, 50%);
    padding: 1em 1.5em;
    height: 100%;
    border-radius: .5em;
    overflow-y: scroll;
    overflow-x: hidden;
    
    @media (max-width: 768px) {
        font-size: small;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
        border: 0;
        opacity: .5;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        opacity: .5;
}
    
    .anchor {
        position: relative;
        top: -4rem;
    }
    
    .intro span {
        font-weight: bold;
    }
    
    a {
        font-weight: normal!important;
        text-decoration: none!important;
        transition: filter .3s ease-out;
        will-change: filter;
        word-break: break-word;
        width: 100%;
        cursor: pointer;
        
        &::first-letter {
            font-weight: bold;
        }
        
        &:hover {
            filter: contrast(.1);
        }
    }
    
    p {
        margin: 0 0 1rem;
        text-align: center;
        font-weight: bold;
    }
    
    .close_table_of_contents_button_wrapper {
        position: fixed;
        right: 1em;
        bottom: 1em;
        border-radius: 50%;
        
        button {
            display: flex;
                flex-direction: column;
                justify-content: center;
            cursor: pointer;
            background: none;
            border: none;
            position: relative;
            padding: .5em;
            border-radius: inherit;
            height: 35px;
            width: 35px;
    
            &::before, &::after {
                position: absolute;
                content: '';
                width: 50%;
                height: 3px; /* cross thickness */
                background-color: var(--black);
            }
    
            &::before {
                transform: translateX(-50%) rotate(45deg);
                right: 0;
            }
    
            &::after {
                transform: translateX(-50%) rotate(-45deg);
                right: 0;
            }
        }
    }
`;

const TableOfContentsWrapper = ({ children }) => {

    const { t } = useTranslation();

    return (
        <StyledTableOfContentsWrapper id="table_of_contents" className='table_of_contents'>
            <div className="anchor"/>
            <p>{t('blog_post.table_of_contents')}</p>
            <a className="intro" role="button" tabIndex="0" onClick={() => scrollTo('.top_anchor')}>
                <span>0.</span> Informacje i wstęp
            </a>
            {children}
            <div className="close_table_of_contents_button_wrapper">
                <button aria-label="Open/close Table of Contents" />
            </div>
        </StyledTableOfContentsWrapper>
    )
}

export default TableOfContentsWrapper;