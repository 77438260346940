import TableOfContentsWrapper from "../../../../src/components/BlogPost/TableOfContents/TableOfContentsWrapper";
import SubSection from "../../../../src/components/BlogPost/TableOfContents/SubSection";
import Section from "../../../../src/components/BlogPost/TableOfContents/Section";
import ScrollAnchor from "../../../../src/components/BlogPost/TableOfContents/ScrollAnchor";
import * as React from 'react';
export default {
  TableOfContentsWrapper,
  SubSection,
  Section,
  ScrollAnchor,
  React
};