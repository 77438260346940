import React, { useState, createContext } from "react"

const FormContext = createContext();

export const FormProvider = ({ children }) => {

    const windowGlobal = typeof window !== 'undefined' && window;

    // const checkForDarkModeCache = () => {
    //     const cache = windowGlobal?.localStorage?.getItem('dark-mode');
    //     if (cache === 'true') {
    //         return true
    //     } else if (cache ==='false') {
    //         return false
    //     } else return false;
    // }
    // const wantDarkMode = checkForDarkModeCache();

    const initialState = {
        step: 1,
        product: '',
        name: '',
        notes: '',
        email: '',
        totalPrice: 0,
        finalProduct: '',
        wantSubscribeToNewsletter: false
    }

    const [state, setState] = useState(initialState);
    const [dollarPrice, setDollarPrice] = useState(null);

    const commonBriefState = {
        companyName: '',
        socialMedia: '',
        brandResources: '',
        designTastes: '',
        uniqueAttributes: '',
        anythingElse: ''
    }

    const websiteState = {
        priceWebsitePages: '',
        priceWebsiteForm: '',
        priceWebsiteBlog: '',
        priceWebsiteConsultation: '',
        priceWebsiteNewsletter: '',
        priceWebsiteChat: '',
        multiplierWebsiteLanguages: '',
        multiplierWebsiteCMS: '',
        multiplierWebsiteDarkMode: '',
        multiplierWebsiteAnimations: '',
        multiplierWebsiteProject: '',
        ...commonBriefState,
        purpose: '',
        targetGroup: '',
        clientsProblems: '',
        concurrencyWebsites: '',
        websiteMap: ''
    },
    priceWebsiteFixedCosts =
        state.priceWebsitePages +
        state.priceWebsiteForm +
        state.priceWebsiteBlog +
        state.priceWebsiteNewsletter +
        state.priceWebsiteChat,
    priceWebsiteMultipliers =
        state.multiplierWebsiteLanguages *
        state.multiplierWebsiteCMS *
        state.multiplierWebsiteDarkMode *
        state.multiplierWebsiteAnimations *
        state.multiplierWebsiteProject,
    priceWebsiteCondition = ([state.priceWebsitePages, state.priceWebsiteForm, state.priceWebsiteBlog, state.priceWebsiteNewsletter, state.priceWebsiteChat, state.multiplierWebsiteLanguages, state.multiplierWebsiteCMS, state.multiplierWebsiteDarkMode, state.multiplierWebsiteProject].includes(''));

    const landingPageState = {
        multiplierLandingPageCMS: '',
        multiplierLandingPageProject: '',
        priceLandingPageGoal: '',
        ...commonBriefState
    },
    priceLandingPageFixedCosts =
        state.priceLandingPageGoal,
    priceLandingPageMultipliers =
        state.multiplierLandingPageCMS *
        state.multiplierLandingPageProject,
    priceLandingPageCondition = ([state.priceLandingPageGoal, state.multiplierLandingPageCMS, state.multiplierLandingPageProject]).includes('');


    const eCommerceState = {
        multiplierEcommerceProductsCount: '',
        priceEcommerceControlProductsDetails: '',
        priceEcommerceAccountRegistration: '',
        priceEcommerceDiscounts: '',
        priceEcommerceStockAvailability: '',
        priceEcommerceLabelProducts: '',
        priceEcommerceIntegration: '',
        priceEcommerceVariants: '',
        priceEcommerceSearchBar: '',
        ...commonBriefState,
        concurrencyWebsites: '',
        ecommerceMap: '',
    },
    priceEcommerceFixedCosts =
        state.priceEcommerceControlProductsDetails +
        state.priceEcommerceAccountRegistration +
        state.priceEcommerceDiscounts +
        state.priceEcommerceStockAvailability +
        state.priceEcommerceLabelProducts +
        state.priceEcommerceIntegration +
        state.priceEcommerceVariants +
        state.priceEcommerceSearchBar,
    priceEcommerceMultipliers =
        state.multiplierEcommerceProductsCount,
    priceEcommerceCondition = ([state.multiplierEcommerceProductsCount, state.priceEcommerceControlProductsDetails, state.priceEcommerceAccountRegistration, state.priceEcommerceDiscounts, state.priceEcommerceStockAvailability, state.priceEcommerceLabelProducts, state.priceEcommerceIntegration, state.priceEcommerceVariants, state.priceEcommerceSearchBar].includes(''));

    const AllStatesForReset = {
        ...initialState,
        ...websiteState,
        ...landingPageState,
        ...eCommerceState,
        ...websiteState
    },
    allStateValues = Object.keys(AllStatesForReset);

    const AllStateAnswersForReset = {
        ...websiteState,
        ...landingPageState,
        ...eCommerceState,
        ...websiteState
    },
    allStateAnswers = Object.keys(AllStateAnswersForReset);

    const getAllSteps = () => {
        switch(state.product) {
            default:
                return 0
            case 'website_type':
                return 1
            case 'website':
                return 11
            case 'website_brief':
                return 13
            case 'landing_page':
                return 3
            case 'ecommerce':
                return 9
            case 'personal-data':
                return 4
        }
    }

    const chooseProduct = (product, step = 1) => {
        switch(product) {
            default:
                setState({
                    ...state,
                    step: step,
                    product: product
                });
                break
            case 'website':
                setState({
                    ...initialState,
                    ...state.totalPrice,
                    ...websiteState,
                    step: step,
                    product: product
                });
                break
            case 'landing_page':
                setState({
                    ...initialState,
                    ...landingPageState,
                    step: step,
                    product: product
                });
                break
            case 'ecommerce':
                setState({
                    ...initialState,
                    ...eCommerceState,
                    step: step,
                    product: product
                });
                break
        }
        // windowGlobal.localStorage.setItem('product', product)
    };

    const nextStep = () => {
        const { step } = state;
        setState({
            ...state,
            step: step + 1
        });
        // windowGlobal.localStorage.setItem('step', state.step);
    };

    const prevStep = () => {
        const { step } = state;
        setState({
            ...state,
            step: step - 1
        });
        // windowGlobal.localStorage.setItem('step', state.step);
    };

    const backToFirstQuestion = (product = '', step = 2) => {
        setState({
            ...state,
            product: product,
            step: step
        });
    };

    const resetForm = () => {
        setState(initialState);
        allStateValues.forEach(item => windowGlobal.localStorage.removeItem(item));
    };

    const resetAnswers = () => {
        setState({
            ...state,
            ...websiteState,
            ...landingPageState,
            ...eCommerceState,
            ...commonBriefState
        })
        allStateAnswers.forEach(item => windowGlobal.localStorage.removeItem(item));
    }

    const handleChange = input => e => {
        e.persist();
        const { step } = state;
        let { value } = e.target;
        value = parseFloat(value);
        setState({
            ...state,
            [input]: value,
            step: step + 1
        });
        windowGlobal.localStorage.setItem(input, value);
    };

    const handleChangeString = input => e => {
        const { step } = state;
        e.persist();
        setState({
            ...state,
            [input]: e.target.value,
            step: step + 1
        });
        windowGlobal.localStorage.setItem(input, e.target.value);
    };

    const handleChangeTextarea = input => e => {
        e.persist();
        setState({
            ...state,
            [input]: e.target.value
        });
        windowGlobal.localStorage.setItem(input, e.target.value);
    };

    const missingAnswers = (currentState) => {
        let states;
        if (currentState === 'website') states = Object.keys(websiteState)
        if (currentState === 'landing_page') states = Object.keys(landingPageState)
        if (currentState === 'ecommerce') states = Object.keys(eCommerceState)
        let indexes = [];
        states.forEach((singleState, index) => {
            if (state[singleState] === '') {
                let indexPlusOne = index + 1;
                indexes.push(indexPlusOne);
            }
        })
        const lowestInArray = Math.min.apply(null, indexes);
        setState({
            ...state,
            step: lowestInArray
        })
    };

    const submitForm = (product) => {
        setState({
            ...state,
            product: product
        })
        windowGlobal.localStorage.setItem('name', state.name);
    };

    function getDollarPrice() {
        fetch('https://api.nbp.pl/api/exchangerates/rates/A/USD/')
            .then(response => response.json())
            .then(result => setDollarPrice(result.rates[0].mid))
            .catch(() => setDollarPrice(3.95));
    };

    return (
        <FormContext.Provider value={{
            state,
            setState,
            priceWebsiteFixedCosts,
            priceWebsiteMultipliers,
            priceWebsiteCondition,
            priceLandingPageFixedCosts,
            priceLandingPageMultipliers,
            priceLandingPageCondition,
            priceEcommerceFixedCosts,
            priceEcommerceMultipliers,
            priceEcommerceCondition,
            windowGlobal,
            getAllSteps,
            nextStep,
            prevStep,
            handleChange,
            handleChangeString,
            handleChangeTextarea,
            backToFirstQuestion,
            missingAnswers,
            chooseProduct,
            resetForm,
            resetAnswers,
            submitForm,
            getDollarPrice,
            dollarPrice,
        }}>
            {children}
        </FormContext.Provider>
    )};

export default FormContext;