import React from 'react';
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';

const StyledSubSection = styled.a`
    display: block;
    scroll-behavior: smooth;
    cursor: pointer;
    width: auto;
    margin-left: 1.5em;
`;


const SubSection = ({ sectionId, children }) => {

    return (
      <StyledSubSection onClick={() => scrollTo(sectionId)} children={children}>
          {children}
      </StyledSubSection>
    )
}

export default SubSection;