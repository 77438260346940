import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
    --scrollValue: 200px;
    //scroll-padding-top: var(--scrollValue);
    //scroll-margin-top: var(--scrollValue);
    // border-bottom: 1px solid red;
    margin-top: 3rem;
    position: relative;
    top: -4rem;
`;

const ScrollAnchor = ({ sectionId }) => {
    return (
        <StyledDiv id={sectionId} />
    )
}

export default ScrollAnchor;